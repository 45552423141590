import React, {Component} from 'react'

import ChampsTable from '../components/ChampsTable';

const paragraphStyle = {
  color: 'white'
};

const tablePStyle = {
  width: '100%',
  overflowX: 'auto'
}

export default class ChampsPage extends Component {


    render() {
        return(
            <div className="App">
              <header className="App-header">
                <p style={tablePStyle} className="table-container-champs">
                    <ChampsTable />
                </p>
              </header>
              <p style={paragraphStyle}>
                * 2023 Championship week had the Bills v Bengals game that never finished. Current leader was declared the winner. Prize pool split was changed after agreement between 2 teams in the championship.
              </p>
            </div>
        );
    }
}
