import React, {Component} from 'react'
import rules from '../assets/images/league_rules.png'

export default class RulesPage extends Component {

    render() {
        return(
            <div className="App">
              <header className="App-header">
                <p>
                    <img src={rules} alt="rules" />
                </p>
              </header>
            </div>
        );
    }
}