import React, {Component} from 'react'
import {connect} from 'react-redux'
import getBallots from '../redux/actions/getBallots';
import PropTypes from 'prop-types'



export const BallotsPage = class extends Component {

    componentDidMount() {
        this.props.getBallots();
    }
    constructor(props) {
        super(props);
        this.state = {value: '', rules: [], submitTriggered: false};

    }

  render() {
        const listOfBallots = this.props.ballots.map((ballot,index) => 
        <div key={index}>
            <h1>-------------------------------------------------</h1>
            <h1 key={index}>User: {ballot['username']}</h1>
            <ul>
            {ballot.votes.map((vote,index2) =>
                <div key={index2}>
                    <li key={index2}>{vote['RuleText']} : {vote['VoteValue']}</li>
                </div>
            )}
            </ul>
            <h1>-------------------------------------------------</h1>
        </div>
        
        )
        return (
        <div>
            <div className="home-container">
                <h2 style={{paddingTop: 100}}>Ballots</h2>
                <h3>---------------------------------------------------------------------------</h3>
                {listOfBallots}
            </div>
        </div>
        );
  }
};

BallotsPage.propTypes = {
    ballots: PropTypes.array,
    getBallots: PropTypes.func
}

const mapStateToProps = (state) => {
    if(state)
    return {
        ballots: state.ballots
    }
}

const ConnectedBallotsPage = connect(mapStateToProps, {
    getBallots
})(BallotsPage);

export default ConnectedBallotsPage;
