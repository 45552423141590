import React, {Component} from 'react';
import { connect } from 'react-redux';
import attemptLogin from '../../redux/actions/loginActions'
import PropTypes from 'prop-types'
import './Login.css';

export const LoginPage = class extends Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {user: '', pass: ''};
  }

  handleSubmit(event) {
    let input = {}
    input["Username"] = this.state.user
    input["Password"] = this.state.pass
    this.props.attemptLogin(input);
    event.preventDefault();
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;

    
    this.setState({
      [name]: target.value    });
  }

  render(){
    return(
      <div className="login-wrapper">
        <h1>Please Log In</h1>
        <form onSubmit={this.handleSubmit}>
          <label>
            <p>Username</p>
            <input type="text" name="user" onChange={this.handleInputChange} />
          </label>
          <label>
            <p>Password</p>
            <input type="password" name="pass" onChange={this.handleInputChange}/>
          </label>
          <div>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    )
  }
};

LoginPage.propTypes = {
  attemptLogin: PropTypes.func
}

const mapStateToProps = (state) => {
  if(state)
  return {

  }
}

const ConnectedLoginPage = connect(mapStateToProps, {
  attemptLogin

})(LoginPage);

export default ConnectedLoginPage