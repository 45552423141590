import React, {Component} from 'react'
import SortableTable from 'react-sortable-table';
window.React = require('react');

export default class ChampsTable extends Component {
    render() {
        const data = [
        {name:"Anthony", ships: "4", playoffs: 10, pp: ((10/11)*(100)).toFixed(2), years: 11, bons: 0, bonApp: 0},
        {name:"Blake", ships: "1", playoffs: 6,pp: ((6/9)*(100)).toFixed(2), years: 9, bons: 2, bonApp: 2},
        {name:"Rick", ships: "1", playoffs: 3,pp: ((3/7)*(100)).toFixed(2), years: 7, bons: 1, bonApp: 2},
        {name:"Korn", ships: "1", playoffs: 2, pp: ((2/11)*(100)).toFixed(2), years: 11, bons: 0, bonApp: 2},
        {name:"Terry", ships: "1", playoffs: 1, pp: ((1/5)*(100)).toFixed(2), years: 5, bons: 1, bonApp: 1},
        {name:"Ryan", ships: "0", playoffs: 1, pp: ((1/1)*(100)).toFixed(2), years: 1, bons: 0, bonApp: 0},
        {name:"Mike", ships: "1", playoffs: 3, pp: ((3/5)*(100)).toFixed(2), years: 5, bons: 0, bonApp: 0},
        {name:"Murphy", ships: "0", playoffs: 0, pp: ((0/2)*(100)).toFixed(2), years: 2, bons: 0, bonApp: 1},
        {name:"Johns friend", ships: "0", playoffs: 1, pp: ((1/1)*(100)).toFixed(2), years: 1, bons: 0, bonApp: 0},
        {name:"SM", ships: "0", playoffs: 1, years: 3, pp: ((1/3)*(100)).toFixed(2), bons: 1, bonApp: 2},
        {name:"Tyler", ships: "0", playoffs: 0, pp: ((0/3)*(100)).toFixed(2), years: 3, bons: 1, bonApp: 1},
        {name:"Spencer", ships: "0", playoffs: 2, pp: ((2/4)*(100)).toFixed(2), years: 4, bons: 0, bonApp: 0},
        {name:"John", ships: "0", playoffs: 4, pp: ((4/5)*(100)).toFixed(2), years: 4.5, bons: 0, bonApp: 0},
        {name:"Chase", ships: "0", playoffs: 0, pp: ((0/4)*(100)).toFixed(2), years: 4, bons: 1, bonApp: 2},
        {name:"Beau", ships: "0", playoffs: 6, pp: ((6/9)*(100)).toFixed(2), years: 9, bons: 0, bonApp: 0},
        {name:"Matt", ships: "0", playoffs: 9, pp: ((9/11)*(100)).toFixed(2), years: 11, bons: 1, bonApp: 1},
        {name:"Pat", ships: "1*", playoffs: 4, pp: ((4/11)*(100)).toFixed(2), years: 11, bons: 2, bonApp: 5},
        {name:"Jarrod", ships: "1", playoffs: 9, pp: ((9/11)*(100)).toFixed(2), years: 11, bons: 1, bonApp: 1},
        {name:"Castin", ships: "0", playoffs: 2, pp: ((2/2)*(100)).toFixed(2), years: 2, bons: 0, bonApp: 0},
        {name:"Brett", ships: "0", playoffs: 1, pp: ((1/1)*(100)).toFixed(2), years: 1, bons: 0, bonApp: 0}
        ];

        const columns = [
            {
                header: 'NAME',
                key: 'name',
                headerStyle: { fontSize: '15px' },
                headerProps: { className: 'align-left' },
            },
            {
                  header: 'Championships',
                  key: 'ships',
                  headerStyle: { fontSize: '15px' },
                  headerProps: { className: 'align-left' },
            },
            {
                header: 'Playoff Appearances',
                key: 'playoffs',
                headerStyle: { fontSize: '15px' },
                headerProps: { className: 'align-left' },
            },
            {
                header: 'Playoff Appearances %',
                key: 'pp',
                headerStyle: { fontSize: '15px' },
                headerProps: { className: 'align-left' },
            }
            ,
            {
                  header: 'Years in the League',
                  key: 'years',
                  headerStyle: { fontSize: '15px' },
                  headerProps: { className: 'align-left' },
            },
            {
                header: 'Sacko/Hock/Bon Wins',
                key: 'bons',
                headerStyle: { fontSize: '15px' },
                headerProps: { className: 'align-left' },
            },
            {
                header: 'Sacko/Hock/Bon Appearances',
                key: 'bonApp',
                headerStyle: { fontSize: '15px' },
                headerProps: { className: 'align-left' },
            }
          
        ];

        const style = {
          backgroundColor: 'rgb(13 70 24)'
        };

        const iconStyle = {
          color: '#aaa',
          paddingLeft: '5px',
          paddingRight: '5px'
        };

        return(
            <SortableTable
                data={data}
                columns={columns}
                style={style}
                iconStyle={iconStyle} />
            );
    }

}
