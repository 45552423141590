/* eslint-disable react/prop-types */
import React from "react";
import data from "../../data/processedData.json";
import UsersTable from "../../components/UsersTable";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink
} from "react-router-dom";

const AllTimeHistory = () => {
    const navigation = [
        { name: 'All Time', href: '/history', current: true },
        { name: 'Seasons', href: '/history/seasons', current: false },
        { name: 'Playoffs', href: '/history/playoffs', current: false }
    ];

    const tableData = React.useMemo(() => Object.values(data.users), []);

    const columns = React.useMemo(() => {
        return {
            allTime: [
                {
                    Header: "Name",
                    accessor: "name",
                },
                {
                    Header: "Total Games",
                    id: "totalGames",
                    Cell: ({ row }) => {
                        let totalGames = row.values.wins + row.values.losses;
                        row.values.totalGames = totalGames;
                        return totalGames;
                    },
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "Wins",
                    accessor: "wins",
                    id: 'wins',
                    sortInverted: true
                },
                {
                    Header: "Losses",
                    accessor: "losses",
                    sortInverted: true
                },
                {
                    Header: "Win %",
                    id: "winPer",
                    Cell: ({ row }) => {
                        let winPer = ((row.values.wins/(row.values.wins + row.values.losses))*100).toFixed(2);
                        row.values.winPer = winPer;
                        return <span>{ winPer + '%'}</span>;
                    },
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "Points For",
                    accessor: "pointsFor",
                    Cell: ({ cell }) => cell.value.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2}),
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "Points Against",
                    accessor: "pointsAgainst",
                    Cell: ({ cell }) => cell.value.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2}),
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "PF/g",
                    id: "pfPerGame",
                    Cell: ({ row }) => {
                        let pfPerGame = (row.values.pointsFor / row.values.totalGames);
                        row.values.pfPerGame = pfPerGame;
                        return pfPerGame.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2})
                    },
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "PA/g",
                    id: "paPerGame",
                    Cell: ({ row }) => {
                        let paPerGame = (row.values.pointsAgainst / row.values.totalGames);
                        row.values.paPerGame = paPerGame;
                        return paPerGame.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2})
                    },
                    canSort: true,
                    sortInverted: true
                },
            ],
            seasons: [
                {
                    Header: "Name",
                    accessor: "name",
                },
                {
                    Header: "Total Games",
                    id: "totalGames",
                    Cell: ({ row }) => {
                        let totalGames = row.values.wins + row.values.seasonLosses;
                        row.values.totalGames = totalGames;
                        return totalGames;
                    },
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "Wins",
                    accessor: "seasonWins",
                    id: 'wins',
                    sortInverted: true
                },
                {
                    Header: "Losses",
                    accessor: "seasonLosses",
                    sortInverted: true
                },
                {
                    Header: "Win %",
                    id: "winPer",
                    Cell: ({ row }) => {
                        let winPer = ((row.values.wins/(row.values.totalGames))*100).toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2});
                        row.values.winPer = winPer;
                        return <span>{ winPer + '%'}</span>;
                    },
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "Points For",
                    accessor: "seasonPf",
                    Cell: ({ cell }) => cell.value.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2}),
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "Points Against",
                    accessor: "seasonPa",
                    Cell: ({ cell }) => cell.value.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2}),
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "PF/g",
                    id: "pfPerGame",
                    Cell: ({ row }) => {
                        let pfPerGame = (row.values.seasonPf / row.values.totalGames);
                        row.values.pfPerGame = pfPerGame;
                        return pfPerGame.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2})
                    },
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "PA/g",
                    id: "paPerGame",
                    Cell: ({ row }) => {
                        let paPerGame = (row.values.seasonPa / row.values.totalGames);
                        row.values.paPerGame = paPerGame;
                        return paPerGame.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2})
                    },
                    canSort: true,
                    sortInverted: true
                },
            ],
            playoffs: [
                {
                    Header: "Name",
                    accessor: "name",
                },
                {
                    Header: "Total Games",
                    id: "totalGames",
                    Cell: ({ row }) => {
                        let totalGames = row.values.wins + row.values.playoffLosses;
                        row.values.totalGames = totalGames;
                        return totalGames;
                    },
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "Wins",
                    accessor: "playoffWins",
                    id: 'wins',
                    sortInverted: true
                },
                {
                    Header: "Losses",
                    accessor: "playoffLosses",
                    sortInverted: true
                },
                {
                    Header: "Win %",
                    id: "winPer",
                    Cell: ({ row }) => {
                        let winPer = row.values.totalGames
                            ? ((row.values.wins/(row.values.totalGames))*100).toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2}) + '%'
                            : '-';
                        row.values.winPer = winPer;
                        return winPer;
                    },
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "Points For",
                    accessor: "playoffPf",
                    Cell: ({ cell, row }) => row.values.totalGames ? cell.value.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2}) : '-',
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "Points Against",
                    accessor: "playoffPa",
                    Cell: ({ cell, row }) => row.values.totalGames ? cell.value.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2}) : '-',
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "PF/g",
                    id: "pfPerGame",
                    Cell: ({ row }) => {
                        let pfPerGame = (row.values.playoffPf / row.values.totalGames);
                        row.values.pfPerGame = pfPerGame;
                        return row.values.totalGames ? pfPerGame.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2}) : '-'
                    },
                    canSort: true,
                    sortInverted: true
                },
                {
                    Header: "PA/g",
                    id: "paPerGame",
                    Cell: ({ row }) => {
                        let paPerGame = (row.values.playoffPa / row.values.totalGames);
                        row.values.paPerGame = paPerGame;
                        return row.values.totalGames ? paPerGame.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2}) : '-'
                    },
                    canSort: true,
                    sortInverted: true
                },
            ],
        };
    }, []);

    return (
        <>
            <div>
            <Router>
                <div className='sub-nav history-sub-nav'>
                    {navigation.map((item) => (
                        <NavLink
                            exact={true}
                            key={item.name} 
                            to={item.href} 
                            activeClassName='bg-gray-900 text-white'
                            className='nav-links text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
                        >
                            {item.name}
                        </NavLink>
                    ))}
                </div>
                <Switch>
                    <Route exact path="/history">
                        <UsersTable
                            data={tableData}
                            columns={columns['allTime']}
                        />  
                    </Route>
                    <Route path="/history/seasons">
                        <UsersTable
                            data={tableData}
                            columns={columns['seasons']}
                        />  
                    </Route>
                    <Route path="/history/playoffs">
                        <UsersTable
                            data={tableData}
                            columns={columns['playoffs']}
                        />  
                    </Route>
                </Switch>
            </Router>
            </div>
        </>
    );
};

export default AllTimeHistory;
