/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React from 'react'
import PropTypes from 'prop-types';
import { useTable, useSortBy } from "react-table";

 const StandingsTable = ({ data, columns }) => {

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            { 
                columns, 
                data,
                // autoResetSortBy: false,
                initialState: React.useMemo(() => {
                        return {
                            sortBy: [
                                {
                                    id: 'wins',
                                    desc: false
                                }
                            ]
                        }
                    },[]) 
            }, useSortBy);

    return (
        <>
            <div id='season-standings-container'>
                <h2>Standings</h2>
                <div id='standings-table-inner'>
                    <table {...getTableProps()} style={{ border: "solid 1px blue" }}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            style={{
                                                borderBottom: "solid 3px red",
                                                background: "aliceblue",
                                                color: "black",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {column.render("Header")}
                                            <span>
                                                {getSortArrow(column)}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    style={{
                                                        padding: "10px",
                                                        border: "solid 1px gray",
                                                        background: "papayawhip",
                                                    }}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );

}

function getSortArrow(column) {
    let ascendingArrow = ' 🔼';
    let descendingArrow = ' 🔽';

    if(column.isSorted) {
        return column.isSortedDesc
            ? !column.sortInverted ? descendingArrow : ascendingArrow
            : !column.sortInverted ? ascendingArrow : descendingArrow
    }
}

StandingsTable.propTypes = {
    data: PropTypes.object,
    year: PropTypes.string,
    columns: PropTypes.array
}

export default StandingsTable;