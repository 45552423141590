import axios from "axios";

export default function submitNewRule(input) {

    console.log("test input:"+input)

    // const fetchOptions = {
    //     method: 'POST'
    // }

    return () => {
        let url = `https://us-central1-fantasy-football-fyg.cloudfunctions.net/submit-new-question`;
        const requestBody = {suggested_rule: input}
        // fetchOptions['body'] = JSON.stringify({suggested_rule: input});
        // let res = await fetch(url, fetchOptions);
        // dispatch({
        //     type: "succ",
        //     payload: res
        // });
        console.log('made it to post')

        return axios.post(url,requestBody)
        .catch(error => {
                            console.log(error);
                     });
    }
}


