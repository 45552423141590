import axios from "axios";

export default function submitVote(input) {

    console.log("test input:"+ JSON.stringify(input))

    //call vote CF
    console.log('made it to vote')
    const tokenString = localStorage.getItem('token');
    let requestBody = {
        rule_id: input.ruleId,
        value: input.value,
        user: input.user,
        token: tokenString
    }

    let url = `https://us-central1-fantasy-football-fyg.cloudfunctions.net/submit-rule-vote`;
    
    return axios.post(url,requestBody).then(() => {
        window.location.reload();
    })
    .catch(error => {
                        console.log(error);
                    });
    
        
}

