import axios from "axios"

export default function attemptLogin(input) {
    console.log('in login function')
    return () => {
        let url = 'https://us-central1-fantasy-football-fyg.cloudfunctions.net/attempt-login'
        axios.post(url,input)
            .then(res => {
                console.log('in post post post')
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('user',input["Username"])
                window.location.reload();
                // dispatch({
                //     type: "login_suc",
                //     payload: res.data
                // })

            }).catch(err => {
                console.log(err)
            });
        
    }
}