import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import getAllRuleSuggestions from '../redux/actions/getAllRuleSuggestions'
import submitVote from '../redux/actions/submitVoteAction'
import getAllVoteResults from '../redux/actions/getAllVoteResultsAction'

function vote(key, value, event) {
    const userString = localStorage.getItem('user');
    let request = {
        ruleId: key,
        value: value,
        user: userString
    }
    submitVote(request)
    event.preventDefault()
}

export const VoteOnRulesPage = class extends Component {

    componentDidMount() {
        this.props.getAllRuleSuggestions();
        this.props.getAllVoteResults();
    }
    constructor(props) {
        super(props);
        this.state = {value: '', rules: [], submitTriggered: false};

    }

  render() {

    const listOfRules = this.props.suggested_rules.map((rule,index) => 
            <li key={index}>{index+1}. {rule.suggested_rule} <button onClick={(event) => {vote(this.props.rule_keys[index], "Y", event)}}>[Yes]</button> | <button onClick={(event) => {vote(this.props.rule_keys[index], "N", event)}}>[No]</button> | <button onClick={(event) => {vote(this.props.rule_keys[index], "?", event)}}>[?]</button></li>
        )
    const listOfVotes = Object.keys(this.props.votes).map((ruleText, keyIndex) => 
    <span key={keyIndex}>
        <li> {ruleText}</li>
        <li>&nbsp;&nbsp; Yes: {this.props.votes[ruleText]["yes_vote_count"]} &nbsp;&nbsp; | &nbsp;&nbsp; No: {this.props.votes[ruleText]["no_vote_count"]} &nbsp;&nbsp; | &nbsp;&nbsp; ?: {this.props.votes[ruleText]["question_vote_count"]}</li>
    </span>
        )

    
        return (
        <div>
            <div className="home-container">
                <h2 style={{paddingTop: 100}}>Vote on Rules Here</h2>
                <h3>---------------------------------------------------------------------------</h3>
                <ol>
                    {listOfRules}
                </ol>
            </div>
            <div className="home-container">
                <h3>---------------------------------------------------------------------------</h3>
                <h2 style={{paddingBottom: 50}}>Results</h2>
                <ol>
                    {listOfVotes}
                </ol>
            </div>
        </div>
        );
  }
};

VoteOnRulesPage.propTypes = {
    getAllRuleSuggestions: PropTypes.func,
    suggested_rules: PropTypes.array,
    rule_keys: PropTypes.array,
    getAllVoteResults: PropTypes.func,
    votes: PropTypes.object
}

const mapStateToProps = (state) => {
    if(state)
    return {
        suggested_rules: state.allSugRules,
        rule_keys: state.allSugRulesKeys,
        votes: state.votes
    }
}

const ConnectedVoteOnRulesPage = connect(mapStateToProps, {
    getAllRuleSuggestions,
    getAllVoteResults
})(VoteOnRulesPage);

export default ConnectedVoteOnRulesPage;
