/* eslint-disable react/prop-types */
import React from 'react';
import data from '../../data/processedData.json';
import StandingsTable from '../../components/StandingsTable';
import PlayoffsTable from '../../components/PlayoffsTable';
import {
    NavLink,
    useLocation
} from "react-router-dom";

const Seasons = () => {
    const seasonOptions = Object.keys(data.seasons).sort((a, b) => b - a);

    let query = useQuery();
    let selectedSeason = query.get("s") ? query.get("s") : seasonOptions[0];
    let seasonData = React.useMemo(() => Object.values(data.seasons[selectedSeason].standings), [selectedSeason]);
    let playoffData = React.useMemo(() => data.seasons[selectedSeason].playoffs, [selectedSeason]);

    const columns = React.useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Wins",
            accessor: "wins",
            canSort: true,
            sortInverted: true
        },
        {
            Header: "Losses",
            accessor: "losses",
            canSort: true,
            sortInverted: true
        },
        {
            Header: "Win %",
            id: "winPer",
            Cell: ({ row }) => {
                let winPer = ((row.values.wins/(row.values.wins + row.values.losses))*100);
                row.values.winPer = winPer;
                return  winPer.toFixed(2) + '%';
            },
            canSort: true,
            sortInverted: true
        },
        {
            Header: "Points For",
            accessor: "pointsFor",
            Cell: ({ cell }) => cell.value.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2}),
            canSort: true,
            sortInverted: true
        },
        {
            Header: "Points Against",
            accessor: "pointsAgainst",
            Cell: ({ cell }) => cell.value.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2}),
            canSort: true,
            sortInverted: true
        },
        {
            Header: "PF/g",
            id: "pfPerGame",
            Cell: ({ row }) => {
                let pfPerGame = (row.values.pointsFor / (row.values.wins + row.values.losses));
                row.values.pfPerGame = pfPerGame;
                return pfPerGame.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2})
            },
            canSort: true,
            sortInverted: true
        },
        {
            Header: "PA/g",
            id: "paPerGame",
            Cell: ({ row }) => {
                let paPerGame = (row.values.pointsAgainst / (row.values.wins + row.values.losses));
                row.values.paPerGame = paPerGame;
                return paPerGame.toLocaleString("en-US", {maximumFractionDigits:2, minimumFractionDigits:2});
            },
            canSort: true,
            sortInverted: true
        },
    ], []);

    return (
        <>
            <div className='sub-nav seasons-table-nav'>
                <div>
                    {seasonOptions.map((year) => (
                        <NavLink
                            key={year} 
                            to={"/seasons?s="+year} 
                            className={year === selectedSeason ? 'nav-links text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium bg-gray-900 text-white' : 'nav-links text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'}
                        >
                            {year}
                        </NavLink>
                    ))}
                </div>
            </div>
            <div>
                <StandingsTable data={seasonData} year={selectedSeason} columns={columns} />
                {playoffData.teams.length && <PlayoffsTable data={playoffData} />}
            </div>
        </>
    );
};

export default Seasons;

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}