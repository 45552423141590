import React from 'react';
import PropTypes from 'prop-types';

import CountdownBox from './CountdownBox';
import '../../assets/css/countdown.css';

const Countdown = ({days, hours, minutes, seconds}) => {
    return (
        <div className="countdown-time-container">
            <CountdownBox time={days} label={"Days"} />
            <CountdownBox time={hours} label={"Hours"} />
            <CountdownBox time={minutes} label={"Minutes"} />
            <CountdownBox time={seconds} label={"Seconds"} />
        </div>
    )
}

Countdown.propTypes = {
    days: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    seconds: PropTypes.string
}

export default Countdown;