import axios from "axios";

export default function getAllVoteResults() {
    // const fetchOptions = {
    //     method: 'POST'
    // }

    return dispatch => {
        let url = `https://us-central1-fantasy-football-fyg.cloudfunctions.net/get-vote-results`;
        return axios.post(url,{})
                     .then(res => {
                            dispatch({
                                type: "get_votes_suc",
                                payload: res.data
                            })
                     }).catch(error => {
                            console.log(error);
                     });
    }
}


