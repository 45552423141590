import './assets/css/App.css';
import React, {Component}from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import ChampsPage from './pages/ChampsPage.jsx'
import Home from './pages/Home.jsx'
// import DraftPage from './pages/DraftPage.jsx'
import RulesPage from './pages/RulesPage.jsx'
import NewRulesPage from './pages/NewRules';
import History from './pages/History/History.jsx'
import VoteOnRulesPage from './pages/VoteOnRules.jsx'
import LoginPage from './components/Login/Login'
import BallotsPage  from './pages/BallotsPage';
import Navigation from './components/Navigation/Navigation'
import getToken from './components/Login/useToken'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import _ from 'lodash'
import getSampleAxiosData from './redux/actions/sampleAxiosAction'

export const App = class extends Component {

    componentDidMount() {
        // this.props.getSampleAxiosData();
    }

  render() {

    console.log(this.props.token)

    // if(!this.props.token || _.isEmpty(this.props.token)){
    //   return <LoginPage/>
    // }
    if(!getToken() || _.isEmpty(getToken())){
      return <LoginPage/>
    }

    return (
      <Router>
        <Navigation />
        <div className="App-body">
          <Switch>
            <Route path="/champs" component={ChampsPage} />
            <Route path="/rules" component={RulesPage} />
            <Route path="/history" component={History} />
            <Route path="/seasons" component={History} />
            <Route path="/new_rule" component={NewRulesPage} />
            <Route path="/vote_on_rules" component={VoteOnRulesPage} />
            <Route path="/ballots" component={BallotsPage} />
            <Route exact path="/" component={Home} />
          </Switch>
        </div>

      </Router>
    );
  }
};

App.propTypes = {
    getSampleAxiosData: PropTypes.func,
    token: PropTypes.string
}

const mapStateToProps = (state) => {
    if(state)
    return {
      token: state.token
    }
}

const ConnectedApp = connect(mapStateToProps, {
    getSampleAxiosData
})(App);

export default ConnectedApp;
