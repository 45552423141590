import axios from 'axios';

export default function getSampleAxiosData() {

    return dispatch => {


        let url = `https://jsonplaceholder.typicode.com/users`;

        return axios.get(url)
                     .then(res => {
                            dispatch({
                                type: "test_type",
                                payload: res.data
                            })
                     }).catch(error => {
                            console.log(error);
                     });
    }
}