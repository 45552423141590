import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import getSampleAxiosData from '../redux/actions/sampleAxiosAction'
import submitNewRule from '../redux/actions/submitNewRuleAction'
import getAllRuleSuggestions from '../redux/actions/getAllRuleSuggestions'

export const NewRulesPage = class extends Component {

    componentDidMount() {
        this.props.getAllRuleSuggestions();
    }
    constructor(props) {
        super(props);
        this.state = {value: '', rules: [], submitTriggered: false};
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }

      handleChange(event) {
        this.setState({value: event.target.value});
      }
    
      async handleSubmit(event) {
        this.setState({submitTriggered: true})
        alert('Your new rule was submitted: ' + this.state.value);
        await this.props.submitNewRule(this.state.value)
        event.preventDefault();
        this.setState({value: ""})
        this.props.getSampleAxiosData(this.state.value)
        window.location.reload();
      }

  render() {
    
    const listOfRules = this.props.suggested_rules.map((rule,index) => 
            <li key={index}>{index+1}. {rule.suggested_rule}</li>
        )

    if (this.state.submitTriggered == true) {
        return (
            <div></div>
        )
    }else{
    
        return (
            <div>
            <div className="home-container">
        <form onSubmit={this.handleSubmit}>
            <label>
                New Rule:
                <textarea
                    value={this.state.value}
                    onChange={this.handleChange}
                />
            </label>
            <input type="submit" value="submit" />
        </form>
        </div>
        <div className="home-container">
            <h2 style={{paddingTop: 100}}>Suggested Rules</h2>
            <h3>--------------------------------------------------</h3>
            <ol>
                {listOfRules}
            </ol>
        </div>
        </div>
        );
    }
  }
};

NewRulesPage.propTypes = {
    getSampleAxiosData: PropTypes.func,
    submitNewRule:  PropTypes.func,
    getAllRuleSuggestions: PropTypes.func,
    suggested_rules: PropTypes.array
}

const mapStateToProps = (state) => {
    if(state)
    return {
        suggested_rules: state.allSugRules
    }
}

const ConnectedNewRulesPage = connect(mapStateToProps, {
    getSampleAxiosData,
    submitNewRule,
    getAllRuleSuggestions
})(NewRulesPage);

export default ConnectedNewRulesPage;
