import React from 'react';
import PropTypes from 'prop-types';

const CountdownBox = ({ time, label }) => {
    return (
        <div className="countdown-box">
            <div className="countdown-time rounded-md">{time}</div>
            <span className="countdown-label">{label}</span>
        </div>
    );
}

CountdownBox.propTypes = {
    time: PropTypes.string,
    label: PropTypes.string
}

export default CountdownBox;