import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import sampleAxiosReducer from './reducers/sampleAxiosReducer';
import getRulesReducer from './reducers/getRulesReducer';
import getAllRulesKeysReducer from './reducers/getAllRuleKeysReducer';
import getVotesReducer from './reducers/getVotesReducer';
import getBallotsReducer from './reducers/getBallotsReducer';
// import loginReducer from './reducers/loginReducer';

export default function configureStore(initialState) {
    let reducer = combineReducers({
        firstRedux: sampleAxiosReducer,
        allSugRules: getRulesReducer,
        allSugRulesKeys: getAllRulesKeysReducer,
        votes: getVotesReducer,
        ballots: getBallotsReducer
    });

    let enhancements = [applyMiddleware(thunk)];

    if(window.__REDUX_DEVTOOLS_EXTENSION__){
        enhancements.push(window.__REDUX_DEVTOOLS_EXTENSION__())
    }

    return createStore(reducer, initialState, compose(...enhancements))

}