import React, {Component} from 'react'
import logo from '../salt.gif';
import Countdown from 'react-countdown';
import fugLogo from '../assets/images/fug-logo.png';
import moment from 'moment';
import DraftCountdown from '../components/Countdown/Countdown';

export default class Home extends Component {

    render() {
      const date_string = 'August 24, 2024 20:00:00 EDT';
      var date1 = new Date(date_string);
      return (
            <div className="home-container">
              <span>Fuck You Gumby - Year 12</span>
              <span>Congratulations Mike!!
              </span>
              <img className="homeMainIcon" src={fugLogo} alt="Main Logo" />
              <div className="countdown-container">
                <span className="countdown-header">Countdown to Draft Day</span>
                <Countdown renderer={renderer} date={date1} />
                <div className="countdown bg-gray-700 rounded-md">
                  <span>{moment(date_string).format('MMMM Do YYYY')}</span>
                  <span>{moment(date_string).format('h:mm a')}</span>
                </div>
              </div>
              <img src={logo} alt="logo" />
            </div>
        );
    }
}

const Completionist = () => <span>Draft Time</span>;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <DraftCountdown days={days} hours={hours} minutes={minutes} seconds={seconds} />
    );
  }
};