/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types';

const PlayoffsTable = ({ data }) => {

    return (
        <>
            <div id='season-playoffs-container'>
                <h2>Playoffs</h2>
                <PlayoffBracket title="Championship" data={data} bracketData={data.champ} weeks={['W1','W2','W3']} />
                <PlayoffBracket data={data} bracketData={data.champ} weeks={['WC1','WC2','WC3']} />
                <PlayoffBracket title="The Bonnie" data={data} inverted={true} bracketData={data.cons} weeks={['C1','C2','C3']} />
            </div>
        </>
    );
}

PlayoffsTable.propTypes = {
    data: PropTypes.object
}

const PlayoffBracket = ({title, inverted = false, data, bracketData, weeks}) => {
    return (
        <>
            {title && <h3>{title}</h3>}
            <div className='playoff-weeks-headers'>
                {!Object.keys(bracketData).includes(weeks[0]) && <div className='playoff-week-empty-container'></div>}
                {!Object.keys(bracketData).includes(weeks[1]) && <div className='playoff-week-empty-container'></div>}
                {Object.keys(bracketData).map((week, i) => {
                    if((weeks).includes(week)) {
                        return <div key={i} className='playoff-week-header'>{week}</div>
                    }
                })}
            </div>
            <div className='playoff-weeks'>
                {!Object.keys(bracketData).includes(weeks[0]) && <div className='playoff-week-empty-container'></div>}
                {!Object.keys(bracketData).includes(weeks[1]) && <div className='playoff-week-empty-container'></div>}
                {Object.keys(bracketData).map((week, i) => {
                    if((weeks).includes(week)) {
                        return (
                            <div key={i} className='playoff-week-container'>
                                {week === 'W1' && <ByeMatchup name={data.byes[0].name} />}
                                {bracketData[week].map((matchup, x) => <PlayoffMatchup inverted={inverted} key={x} matchup={matchup} />)}
                                {week === 'W1' && <ByeMatchup name={data.byes[1].name} />}
                            </div>
                        );
                    }
                })}
            </div>
        </>
    )
}

const PlayoffMatchup = ({inverted = false, matchup}) => {
    let classNames = [
        inverted ? 'inverted' : '',
        matchup.winnerId === matchup.homeUserId ? 'matchup-winner' : 'matchup-loser',
        matchup.winnerId === matchup.awayUserId ? 'matchup-winner' : 'matchup-loser'
    ];

    return (
        <div className='playoff-matchup-container'>
            <div className={classNames[0] + ' ' + classNames[1]}>
                <span>{matchup.homeUser}</span>
                <span>{matchup.homeScore}</span>
            </div>
            <div className={classNames[0] + ' ' + classNames[2]}>
                <span>{matchup.awayUser}</span>
                <span>{matchup.awayScore}</span>
            </div>
        </div>
    );
}

const ByeMatchup = ({name}) => {
    return (
        <div className='playoff-matchup-container'>
            <div>{name + ' - Bye'}</div>
        </div>
    );
}



export default PlayoffsTable;