import React from 'react';
// import data from '../../data/processedData.json';
import Seasons from './Seasons';
import AllTimeHistory from './AllTimeHistory';
import PropTypes from "prop-types";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink
} from "react-router-dom";
import "../../assets/css/history.css";
  

const History = () => {
    const navigation = [
        { name: 'History', href: '/history', current: true },
        { name: 'Seasons', href: '/seasons', current: false }
    ];


    return (
        <>  
            <Router>
                <div id='history-page'>
                    <div className='sub-nav history-nav'>
                        {navigation.map((item) => (
                            <NavLink
                                key={item.name} 
                                to={item.href} 
                                activeClassName='bg-gray-900 text-white'
                                className='nav-links text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
                            >
                                {item.name}
                            </NavLink>
                        ))}
                    </div>
                    <Switch>
                        <Route path="/history" component={AllTimeHistory} />
                        <Route path="/seasons" component={Seasons} />
                    </Switch>
                </div>
            </Router>
            

            
        </>
    );
};

History.propTypes = {
    match: PropTypes.object
};

export default History;